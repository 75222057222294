import ENV from 'additive-voucher/config/environment';

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { waitForProperty } from 'ember-concurrency';

import AuthRouteMixin from '@additive-apps/auth/mixins/auth-route-mixin';
import { fetchAccessibleApps } from '@additive-apps/ui/utils/app-utils';
import { APP_ID_VOUCHER, APP_ID_NEWSLETTER } from '@additive-apps/ui/constants';
import { gte, PLAN_PROFESSIONAL } from '@additive-apps/utils/utils/plans';
import Userback from '@userback/widget';

export default class InstanceRoute extends Route.extend(AuthRouteMixin) {
  @service authenticatedFetch;
  @service currentUser;
  @service session;
  @service store;
  @service uiAppSettings;
  @service uiLocale;

  async model(params) {
    const organization = await this.fetchOrganizationData(params.instance_id);

    return organization;
  }

  async fetchOrganizationData(organizationId) {
    const organization = await this.store.findRecord('organization', organizationId);
    this.currentUser.currentOrganization = organization;

    let tasks = [];
    tasks.push(this.currentUser.loadUser());
    tasks.push(this.uiAppSettings.loadLanguages());
    await Promise.all(tasks);

    !fetchAccessibleApps.task.isRunning && fetchAccessibleApps.task.perform(this, organization.id);

    await this.uiLocale.setLocale(
      this.currentUser?.user?.language || this.currentUser?.currentOrganization?.language || 'de'
    );

    await waitForProperty(fetchAccessibleApps.task, 'isRunning', false);
    const filteredAccessibleApps = this.currentUser.currentOrganization.accessibleApps?.filter(
      (app) => [APP_ID_VOUCHER, APP_ID_NEWSLETTER].includes(app.id)
    );

    let hasIncentiveVouchers = false;
    if (filteredAccessibleApps.length === 2) {
      hasIncentiveVouchers = filteredAccessibleApps.every((app) => {
        const appPlan = app.planName.split('_')[0];
        return gte(appPlan, PLAN_PROFESSIONAL);
      });
    }

    const currentOrganization = this.currentUser.currentOrganization;
    currentOrganization.hasIncentiveVouchers = hasIncentiveVouchers;

    // use setter to trigger recalculations of getters that rely on currentOrganization
    this.currentUser.set('currentOrganization', currentOrganization);

    if (this.currentUser.isAdditiveUser || this.currentUser.isPartnerUser) {
      const widgetKey = ENV.APP.productlaneWidgetKey;

      // Ensure the script is loaded if not already
      if (!document.getElementById('productlane-script')) {
        const script = document.createElement('script');
        script.id = 'productlane-script';
        script.async = true;
        script.defer = true;
        script.crossOrigin = 'anonymous';
        script.src = 'https://widget.productlane.com/latest.productlane-widget.min.js';
        document.body.appendChild(script);
      }

      ((w) => {
        const P = (w.Productlane = { queue: {} });
        ['set', 'open', 'close', 'toggle', 'on', 'off', 'init'].forEach((m) => {
          P[m] = ((n) =>
            function () {
              P.queue[n] = { args: arguments };
            })(m);
        });
      })(window);

      window.Productlane.init({
        widgetKey,
        theme: {
          '--text-color-primary-dark': '#00172F',
          '--text-color-secondary-dark': '#8F9EA4',
          '--text-color-highlight-dark': '#4d76f1',
          '--background-color-primary-dark': '#ffffff',
          '--background-color-secondary-dark': '#8f9ea41a',
          '--border-color-primary-dark': '#061C2D1A',
          '--text-color-primary': '#00172F',
          '--text-color-secondary': '#8F9EA4',
          '--text-color-highlight': '#4d76f1',
          '--background-color-primary': '#ffffff',
          '--background-color-secondary': '#8f9ea41a',
          '--border-color-primary': '#061C2D1A'
        },
        user: {
          email: this.currentUser.organizationUser.email
        }
      });

      window.Productlane.on('loaded', () => {
        const widget = document.getElementById('ProductlaneWidget-MainWidget')?.parentElement;
        widget.style.zIndex = '9999999999';

        const hasPermanentNavdrawer = document.querySelector('.ui-navigation-drawer--permanent');
        if (hasPermanentNavdrawer) {
          widget.style.left = '284px';
        } else {
          widget.style.left = '0px';
        }
      });
    }

    if (this.currentUser.isAdditiveUser || this.currentUser.isPartnerUser) {
      Userback(
        this.currentUser.isAdditiveUser
          ? ENV.APP.userbackAccessToken
          : ENV.APP.userbackAccessTokenPartner,
        {
          email: this.currentUser.organizationUser.email,
          name: this.currentUser.organizationUser.fullName,
          categories: 'Gutscheine'
        }
      );
    }

    return organization;
  }

  @action
  loading() {
    return !this.currentUser.currentOrganization;
  }
}
